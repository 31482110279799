<!-->
  开放平台名称校验修稿原来的文件:  https://git.code.tencent.com/zhixinlian/frontend-toolbox/blob/1a5d88f67f49dfd844bf51680b0522f514552a86/src/views/EnterpriseInfo.vue
<-->
<template>
  <div class="auth">
    <AppHeader :type="1"></AppHeader>
    <div class="content">
      <Regstep :emialInfo="emialInfo" :companyInfo="companyInfo"></Regstep>
      <!-- <img class="topImg"
           src="@/assets/auth.png"
           alt /> -->
      <!-- <p class="title">企业资质</p> -->
      <p class="title">企业实名信息</p>
      <el-form
        class="form"
        :rules="rules"
        ref="form"
        :model="form"
        label-width="80px"
      >
        <div class="form-top">
          <el-form-item label="企业 (机构)名称：" prop="corName">
            <el-input
              maxlength="30"
              placeholder="请输入企业名称"
              v-model="form.corName"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属行业：" prop="value">
            <el-cascader
              v-model="form.value"
              :options="options"
              @active-item-change="handleItemChange"
              :props="{
                value: 'id',
                label: 'name',
                children: 'children'
              }"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="统一社会信用代码：" prop="credit">
            <el-input
              maxlength="18"
              placeholder="请输入社会信用代码"
              v-model="form.credit"
            ></el-input>
          </el-form-item>
          <el-form-item label="法定代表人：" prop="represent">
            <el-input
              maxlength="30"
              placeholder="请输入法定代表人"
              v-model="form.represent"
            ></el-input>
          </el-form-item>
          <!-- 接入平台名称/^[a-zA-Z0-9]{0,20}$/ -->
          <el-form-item label="接入平台名称：" prop="platformName">
            <el-input
              maxlength="20"
              placeholder="中英文数字均可,不超过20字"
              v-model="form.platformName"
            ></el-input>
          </el-form-item>
          <!-- 平台地址 -->
          <el-form-item label="平台地址：">
            <el-input
              maxlength="300"
              placeholder="域名长度不超过300位"
              v-model="form.platformUrl"
            ></el-input>
          </el-form-item>
          <!-- 平台业务类型下拉 金融类 版权类 其他类 -->
          <el-form-item label="平台业务类型：" class="xiala">
            <el-select v-model="form.businessType" placeholder="请选择">
              <el-option
                v-for="item in businessTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form2">
          <el-form-item label="企业 (机构)工商营业执照扫描件：" prop="imageUrl">
            <i
              @click="
                form.imageUrl = '';
                pdfUrl = '';
                busiLicenseId = '';
              "
              v-if="form.imageUrl"
              style="color: rgb(211, 67, 73);position: absolute;right: 54px;top: -5px;z-index: 5;"
              class="el-icon-error"
            ></i>
            <el-upload
              ref="upload"
              class="avatar-uploader"
              :show-file-list="false"
              action="/api/ump/ep/upload"
              :headers="uploadHeaders"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :disabled="form.imageUrl !== ''"
            >
              <img
                @click="
                  viewImg(
                    pdfUrl ? pdfUrl : form.imageUrl,
                    pdfUrl ? 'pdf' : 'image'
                  )
                "
                v-if="form.imageUrl"
                :src="form.imageUrl"
                class="avatar"
                id="embed"
              />
              <img class="uploadadd" v-else src="@/assets/upload.png" />
            </el-upload>
          </el-form-item>
          <el-form-item label="至信链委托公函盖章扫描件：" prop="imageUrl1">
            <i
              @click="
                form.imageUrl1 = '';
                pdfUrl1 = '';
                letterId = '';
              "
              v-if="form.imageUrl1"
              style="color: rgb(211, 67, 73);position: absolute;right: 54px;top: -5px;z-index: 5;"
              class="el-icon-error"
            ></i>
            <el-upload
              ref="upload1"
              class="avatar-uploader"
              :show-file-list="false"
              :headers="uploadHeaders"
              action="/api/ump/ep/uploadletter"
              :on-success="handleAvatarSuccess1"
              :before-upload="beforeAvatarUpload1"
              :disabled="form.imageUrl1 !== ''"
            >
              <img
                @click="
                  viewImg(
                    pdfUrl1 ? pdfUrl1 : form.imageUrl1,
                    pdfUrl1 ? 'pdf' : 'image'
                  )
                "
                v-if="form.imageUrl1"
                :src="form.imageUrl1"
                class="avatar"
                id="embed1"
              />
              <img class="uploadadd" v-else src="@/assets/upload.png" />
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
      <div class="uploadText">
        <!-- <div>
          <p>上传大小不超过5M</p>
          <p>*上传仅支持PNG、JPG、JPGE、PDF格式</p>
        </div> -->
        <p style="position: relative;top: -60px;left: 86px;">
        <a href="https://www.zxinchain.com/downloads/至信链平台认证公函（企业）.docx"
            download="至信链委托公函模板.docx"
          >
            下载公函模板
          </a>
        </p>
        <p>上传大小不超过5M</p>
        <p>*上传仅支持PNG、JPG、JPGE格式</p>
      </div>
      <div class="footBtn2">
        <el-button class="submit" type="primary" @click="onSubmit('form')"
          >下一步</el-button
        >
        <el-button style="margin-left:35px" class="save" @click="onSave"
          >保存草稿</el-button
        >
      </div>
    </div>
    <AppFooter></AppFooter>
    <el-dialog width="600px" class="img-dialog" :visible.sync="imgDialog">
      <iframe
        height="500px"
        v-if="viewType === 'pdf'"
        frameborder="0"
        width="100%"
        :src="previewImg"
        alt=""
      ></iframe>
      <img v-else :src="previewImg" style="width: 100%" alt="" />
    </el-dialog>
    <el-dialog :visible.sync="centerDialogVisible" width="50%" center>
      <img width="100%" src="@/assets/xieyi.png" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button
          class="submit"
          type="primary"
          @click="centerDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 提示框组件 -->
    <Dialog
      :isvisible.sync="isvisible"
      :dailogData="dailogData"
      @colseDialog="colseDialog"
      @operateButton="operateButton"
    >
    </Dialog>
  </div>
</template>
<script>
import AppHeader from "@/components/layer/AppHeader"
import AppFooter from "@/components/layer/AppFooter"
import Dialog from "@/components/common/Dialog"
import axios from "axios"
import { enterRelInfo } from "./dialog"
import {
  uploadDocument,
  sendApply,
  getTradeParent,
  getTradeChild,
  getApply,
  getUserInfo,
  downloadbusiLicense,
  downloadletter,
  sendPhoneCode,
  updateApply,
  companyVerify
} from "@/api/user"
import { exportExcel } from "@/utils/index"
import { statusCode } from "@/config"
import { setCookie, getCookie, delCookie, getUuid } from "@/utils"
import Regstep from "@/components/common/Regstep"

const defaultImg = require("@/assets/pdf.png")

export default {
  components: {
    AppHeader,
    AppFooter,
    Regstep,
    Dialog
  },
  data() {
    var validateChinglish = (rule, value, callback) => {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "")
      value = value.replace(/\s+/g, "")
      const reg = /^[a-zA-Z\u4e00-\u9fa5()（）]+$/
      if (!value && value !== "0") {
        callback(new Error("法定代表人不能为空"))
      } else if (!reg.test(value)) {
        callback(new Error("法定代表人格式不正确"))
      } else {
        callback()
      }
      // return false;
    }

    var validateNumandEng = (rule, value, callback) => {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "")
      value = value.replace(/\s+/g, "")
      const reg = /^([0-9A-Z]{15}|[0-9A-Z]{18})$/
      if (!value && value !== "0") {
        callback(new Error("社会统一代码不能为空"))
      } else if (!reg.test(value)) {
        callback(new Error("社会统一代码格式不正确"))
      } else {
        callback()
      }
    }
    var validateName = (rule, value, callback) => {
      if (value) {
        const reg = /^[\u4e00-\u9fa5a-zA-Z0-9]{0,20}$/;
        if (!reg.test(value)) {
          callback(new Error("接入平台名称格式不正确"))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      isvisible: false, // 提示框显示
      dailogData: {}, // 提示框数据对象
      emialInfo: {
        type: 3,
        name: "已完成"
      },
      companyInfo: {
        type: 2,
        name: "进行中"
      },
      uploadHeaders: {},
      centerDialogVisible: false,
      imgDialog: false,
      value: [],
      options: [],
      form: {
        corName: "",
        credit: "",
        represent: "",
        businessType: "",
        platformName: "",
        platformUrl: "",
        imageUrl: "",
        imageUrl1: "",
        value: []
      },
      businessTypes: [
        {
          value: 1,
          label: "金融类"
        },
        {
          value: 2,
          label: "版权类"
        },
        {
          value: 3,
          label: "其它类"
        }
      ],
      rules: {
        corName: [
          { required: true, message: "企业名称不能为空", trigger: "blur" }
        ],
        credit: [
          { required: true, trigger: "blur", validator: validateNumandEng }
        ],
        represent: [
          { required: true, trigger: "blur", validator: validateChinglish }
        ],
        platformName: [
          { required: false, trigger: "blur", validator: validateName }
        ],
        imageUrl: [
          {
            required: true,
            message: "请上传企业工商营业执照扫描件",
            trigger: "change"
          }
        ],
        imageUrl1: [
          {
            required: true,
            message: "请上传至信链委托公函盖章扫描件",
            trigger: "change"
          }
        ],
        value: [
          { required: true, message: "所属行业不能为空", trigger: "change" }
        ]
      },
      inter: 60,
      isInter: false,
      imageUrl: "",
      pdfUrl: "",
      pdfUrl1: "",
      imageUrl1: "",
      busiLicenseId: "",
      letterId: "",
      categoryPId: "",
      categoryCId: "",
      state: "",
      previewImg: "",
      viewType: "",
      loading: ""
    }
  },
  mounted() {
    this.uploadHeaders["X-XSRF-TOKEN"] = getCookie("zxtoken")
      ? decodeURIComponent(getCookie("zxtoken"))
      : ""
    this.uploadHeaders["Cloud-Trace-Id"] = getUuid()
    const userData = JSON.parse(localStorage.getItem("userData"))
    const that = this
    that.getTradeParent()
    if (!userData) {
      return
    }
    if (userData.epId === 0) {
      if (localStorage.getItem(`corDataSave${userData.uid}`) != null) {
        const data = JSON.parse(
          localStorage.getItem(`corDataSave${userData.uid}`)
        );
        that.form.corName = data.epName;
        that.form.credit = data.creditCode;
        that.form.represent = data.representative;
        that.form.platformName = data.platformName;
        that.form.platformUrl = data.platformUrl;
        that.form.value = [data.categoryPId, data.categoryCId];
        that.form.businessType = data.businessType || '';
        that.busiLicenseId = data.busiLicenseId;
        that.letterId = data.officialLetterId;
        that.categoryPId = data.categoryPId;
        that.categoryCId = data.categoryCId;
        that.busiLicenseId && that.downloadbusiLicense(that.busiLicenseId);
        that.letterId && that.downloadletter(that.letterId);
        that.getTradeParent();
        if (data.categoryPId !== "") {
          setTimeout(() => {
            that.getTradeChilds(data.categoryPId)
          }, 1000)
        }
      } else {
        that.getTradeParent()
      }
    } else {
      if (localStorage.getItem(`corDataSave${userData.uid}`) != null) {
        const data = JSON.parse(
          localStorage.getItem(`corDataSave${userData.uid}`)
        )
        that.form.corName = data.epName
        that.form.credit = data.creditCode
        that.form.represent = data.representative
        that.form.platformName = data.platformName
        that.form.platformUrl = data.platformUrl
        that.form.value = [data.categoryPId, data.categoryCId]
        that.form.businessType = data.businessType || ''
        that.busiLicenseId = data.busiLicenseId
        that.letterId = data.officialLetterId
        that.categoryPId = data.categoryPId
        that.categoryCId = data.categoryCId
        that.state = 1
        that.busiLicenseId && that.downloadbusiLicense(that.busiLicenseId)
        that.letterId && that.downloadletter(that.letterId)
        that.getTradeParent()
        if (data.categoryPId != "") {
          setTimeout(() => {
            that.getTradeChilds(data.categoryPId)
          }, 1000)
        }
      } else {
        getApply().then(res => {
          this.$message.closeAll()
          if (res.detail) {
            that.state = res.detail.state
            that.form.corName = res.detail.epName
            that.form.credit = res.detail.creditCode
            that.form.represent = res.detail.representative
            that.form.platformName = res.detail.platformName
            that.form.platformUrl = res.detail.platformUrl
            that.form.businessType = res.detail.businessType || ''
            that.busiLicenseId = res.detail.busilicenseid
            that.letterId = res.detail.officialLetterId
            that.categoryPId = res.detail.categoryPid
            that.categoryCId = res.detail.categoryCid
            that.downloadbusiLicense(res.detail.busilicenseid)
            that.downloadletter(res.detail.officialLetterId)
            that.getTradeParent()
            setTimeout(() => {
              that.getTradeChilds(res.detail.categoryPid)
            }, 1000)
          } else {
            this.$message.error(res.retMsg)
          }
        })
      }
    }
  },
  methods: {
    viewImg(url, type) {
      this.previewImg = url
      this.viewType = type
      if (type == "pdf") {
        const isIE = window.ActiveXObject || "ActiveXObject" in window

        if (isIE) {
          this.$confirm(
            '<i style="font-size: 54px;color: #D34349;" class="el-icon-warning"></i><span style="font-size:16px;position: relative;top: -10px;margin-left: 10px;">该浏览器暂不支持pdf预览，推荐使用chrome浏览器</span>',
            "提示",
            {
              confirmButtonText: "确定",
              dangerouslyUseHTMLString: true,
              center: true,
              showCancelButton: false,
              confirmButtonClass: "comfirm"
            }
          ).then(() => {})
        } else {
          this.imgDialog = true
        }
      } else {
        this.imgDialog = true
      }
    },
    validatePhone(value) {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "")
      value = value.replace(/\s+/g, "")
      if (!value && value !== "0") {
        return false
      }
      const reg = /^1[3456789]\d{9}$/
      if (reg.test(value)) {
        return true
      }
      return false
    },
    // validateChinglish(value) {
    //   value =
    //     typeof value === "undefined" || value === null
    //       ? ""
    //       : value.replace(/\s+/g, "");
    //   value = value.replace(/\s+/g, "");
    //   if (!value && value !== "0") {
    //     return false;
    //   }
    //   const reg = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    //   if (reg.test(value)) {
    //     return true;
    //   }
    //   return false;
    // },
    // validateNumandEng(value) {
    //   value =
    //     typeof value === "undefined" || value === null
    //       ? ""
    //       : value.replace(/\s+/g, "");
    //   value = value.replace(/\s+/g, "");
    //   if (!value && value !== "0") {
    //     return false;
    //   }
    //   const reg = /^([0-9A-Z]{15}|[0-9A-Z]{18})$/;
    //   if (reg.test(value)) {
    //     return true;
    //   }
    //   return false;
    // },
    downloadbusiLicense(busiLicenseId) {
      const that = this
      downloadbusiLicense(busiLicenseId)
        .then(
          res =>
            `data:${res.headers["content-type"]};base64,${btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`
        )
        .then(res => {
          this.$message.closeAll()
          this.loading && this.loading.close()
          if (res.indexOf("application/pdf") < 0) {
            // that.imageUrl = res;
            that.form.imageUrl = res
          } else {
            that.pdfUrl = res
            that.form.imageUrl = defaultImg
            // that.imageUrl = defaultImg;
          }
        })
        .catch(() => {
          this.loading && this.loading.close()
        })
    },
    downloadletter(officialLetterId) {
      const that = this
      downloadletter(officialLetterId)
        .then(
          res =>
            `data:${res.headers["content-type"]};base64,${btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`
        )
        .then(res => {
          this.$message.closeAll()
          this.loading && this.loading.close()
          if (res.indexOf("application/pdf") < 0) {
            // that.imageUrl1 = res;
            that.form.imageUrl1 = res
          } else {
            that.pdfUrl1 = res
            that.form.imageUrl1 = defaultImg
            // that.imageUrl1 = defaultImg;
          }
        })
        .catch(() => {
          this.loading && this.loading.close()
        })
    },
    getTradeParent() {
      const data = {
        pid: ""
      }
      const that = this
      getTradeParent(data).then(res => {
        this.$message.closeAll()
        if (res.retCode == 0) {
          this.options = res.detail.map((value, i) => ({
            id: value.id,
            name: value.name,
            children: [
              {
                id: 1,
                name: ""
              }
            ]
          }))
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    handleChange(e) {
      this.form.value = e
      this.categoryPId = e[0]
      this.categoryCId = e[1]
    },
    handleItemChange(e) {
      this.getTradeChilds(e[0])
    },
    getTradeChilds(pid) {
      const data = {
        pid
      }
      getTradeChild(data).then(res => {
        this.$message.closeAll()
        if (res.retCode == 0) {
          this.options.map((value, i) => {
            value.children.length = 0
            if (value.id === pid) {
              if (!value.children.length) {
                value.children = res.detail.map((value, i) => ({
                  id: value.id,
                  name: value.name
                }))
              }
            }
          })
          this.value = [this.categoryPId, this.categoryCId]
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    onSubmit(formName) {
      this.$message.closeAll()
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        epName: this.form.corName,
        creditCode: this.form.credit,
        representative: this.form.represent,
        busiLicenseId: this.busiLicenseId,
        officialLetterId: this.letterId,
        categoryPId: this.categoryPId,
        categoryCId: this.categoryCId,
        type: userData.epId === 0 ? 1 : 2,
        platformName: this.form.platformName,
        platformUrl: this.form.platformUrl,
        businessType: this.form.businessType
      }
      //表单验证通过调用接口
      this.$refs[formName].validate(valid => {
        if (valid) {
          const param = {
            creditCode: data.creditCode,
            epName: encodeURI(data.epName),
            representative: encodeURI(data.representative)
          }
          companyVerify(param).then(res => {
            if (res.retCode == 0) {
              localStorage.setItem(
                `corDataSave${userData.uid}`,
                JSON.stringify(data)
              )
              this.$router.push({
                name: "applicantAuth"
              })
            } else {
              this.$message.error(res.retMsg)
            }
          })
        } else {
          return false
        }
      })
      // if (data.epName.trim() === "") {
      //   this.$message.error("企业名称不能为空");
      //   // } else if (!this.validateChinglish(data.epName)) {
      //   //   this.$message.error('企业名称格式不正确')
      // } else if (data.categoryPId === "") {
      //   this.$message.error("所属行业不能为空");
      // } else if (data.creditCode.trim() === "") {
      //   this.$message.error("统一社会信用代码不能为空");
      // } else if (!this.validateNumandEng(data.creditCode)) {
      //   this.$message.error("统一社会信用代码格式不正确");
      // } else if (data.representative.trim() === "") {
      //   this.$message.error("法定代表人不能为空");
      // } else if (!this.validateChinglish(data.representative)) {
      //   this.$message.error("法定代表人格式不正确");
      // } else if (data.officialLetterId === "") {
      //   this.$message.error("请上传至信链委托公函盖章扫描件");
      // } else if (data.busiLicenseId === "") {
      //   this.$message.error("请上传企业工商营业执照扫描件");
      // } else {
      //   const param = {
      //     creditCode: data.creditCode,
      //     epName: encodeURI(data.epName),
      //     representative: encodeURI(data.representative)
      //   };
      //   companyVerify(param).then(res => {
      //     if (res.retCode == 0) {
      //       localStorage.setItem(
      //         `corDataSave${userData.uid}`,
      //         JSON.stringify(data)
      //       );
      //       this.$router.push({
      //         name: "applicantAuth"
      //       });
      //     } else {
      //       this.$message.error(res.retMsg);
      //     }
      //   });
      // }
    },
    getUserInfo() {
      getUserInfo().then(res => {
        this.$message.closeAll()
        if (res.retCode === 0) {
          localStorage.setItem("userData", JSON.stringify(res.detail))
          this.$router.push({ path: "/authSuccess" })
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    onSave() {
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        epName: this.form.corName,
        creditCode: this.form.credit,
        representative: this.form.represent,
        platformName: this.form.platformName,
        platformUrl: this.form.platformUrl,
        businessType: this.form.businessType,
        busiLicenseId: this.busiLicenseId,
        officialLetterId: this.letterId,
        categoryPId: this.categoryPId,
        categoryCId: this.categoryCId,
        type: userData.epId === 0 ? 1 : 2
      }
      localStorage.setItem(`corDataSave${userData.uid}`, JSON.stringify(data))
      this.isvisible = true
      this.dailogData = enterRelInfo.saveDraftS
    },
    getVertify() {
      this.$message.closeAll()
      if (this.form2.applyPhone.trim() === "") {
        this.$message.error("联系人手机号不能为空")
      } else if (!this.validatePhone(this.form2.applyPhone)) {
        this.$message.error("手机号码格式不正确")
      } else {
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        })
        this.sendPhoneCode()
      }
    },
    sendPhoneCode() {
      const data = {
        mobile: this.form2.applyPhone,
        type: this.state === "" ? 1 : 2
      }
      sendPhoneCode(data).then(res => {
        this.$message.closeAll()
        this.loading.close()
        if (res.retCode === 0) {
          this.isInter = true
          const that = this
          const inters = setInterval(() => {
            that.inter--
            if (that.inter <= 0) {
              clearInterval(inters)
              that.isInter = false
              that.inter = 60
            }
          }, 1000)
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.$refs.form.clearValidate("imageUrl")
      this.busiLicenseId = res.busiLicenseId
      if (res.busiLicenseId != undefined) {
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        })
        this.downloadbusiLicense(res.busiLicenseId)
      } else {
        for (const key in statusCode) {
          if (key == res.retCode) {
            this.$message.error(statusCode[key])
            break
          }
        }
      }
    },
    beforeAvatarUpload(file) {
      this.$message.closeAll()
      const isJPG = file.type
      if (
        isJPG !== "image/png" &&
        isJPG !== "image/jpeg" &&
        isJPG.indexOf("pdf") < 0
      ) {
        this.$message.error("上传仅支持PNG、JPG、JPGE格式!")
        return false
      } else if (file.size >= 5242880) {
        this.$message.error("该信息上传大小不得超过5M")
        return false
      }
    },
    handleAvatarSuccess1(res, file) {
      this.$refs.form.clearValidate("imageUrl1")
      this.letterId = res.letterId
      if (res.letterId != undefined) {
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        })
        this.downloadletter(res.letterId)
      } else {
        for (const key in statusCode) {
          if (key == res.retCode) {
            this.$message.error(statusCode[key])
            break
          }
        }
      }
    },
    beforeAvatarUpload1(file) {
      this.$message.closeAll()
      const isJPG = file.type
      if (
        isJPG != "image/png" &&
        isJPG != "image/jpeg" &&
        isJPG.indexOf("pdf") < 0
      ) {
        this.$message.error("上传仅支持PNG、JPG、JPGE格式!")
        return false
      } else if (file.size >= 5242880) {
        this.$message.error("该信息上传大小不得超过5M")
        return false
      }
    },
    colseDialog(val) {
      this.isvisible = val
    },
    operateButton(val) {
      if (val == "search-confirm") {
        //直接关闭
        this.isvisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth {
  min-height: 100%;
  ::v-deep .el-dialog--center {
    width: 50%;
    max-height: 90%;
    top: 5%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 0 !important;
    ::v-deep .el-dialog__footer {
      position: fixed;
      bottom: 5%;
      text-align: center;
      width: 48%;
      background: #fff;
      .submit {
        width: 160px;
        height: 50px;
        font-size: 18px;
        color: #fff;
        background: linear-gradient(
          -90deg,
          rgba(49, 97, 187, 1) 0%,
          rgba(42, 72, 156, 1) 100%
        );
        border-radius: 4px;
        border: none;
      }
    }
  }
  .content {
    width: 1000px;
    height: auto;
    box-shadow: 0px 14px 13px 0px rgba(186, 191, 198, 0.1);
    margin: 0 auto;
    padding-top: 85px;
    ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #3160ba;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #3160ba;
      border-color: #3160ba;
    }
    ::v-deep .el-cascader,
    .el-select {
      width: 100%;
    }
    ::v-deep .avatar-uploader .el-upload {
      border: none;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      z-index: 100;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .uploadadd {
      width: 76px;
      height: 76px;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 76px;
      height: 76px;
      line-height: 76px;
      text-align: center;
    }
    .avatar {
      width: 76px;
      height: 76px;
      display: block;
    }
    .suf {
      position: absolute;
      right: 0;
    }
    .suffix {
      font-style: normal;
      margin-right: 20px;
      font-size: 14px;
      color: #244bb0;
      cursor: pointer;
    }
    .suffix2 {
      font-style: normal;
      margin-right: 20px;
      font-size: 14px;
      color: #b4b4b4;
    }
    ::v-deep .el-form-item {
      margin-bottom: 32px;
    }
    ::v-deep .el-form-item__label {
      width: 150px !important;
      color: #333333 !important;
    }
    ::v-deep .el-form-item__content {
      margin-left: 150px !important;
    }
    .title {
      color: #333333;
      font-size: 16px;
      margin-top: 33px;
      text-align: center;
    }
    .topImg {
      width: 100%;
      height: 157px;
    }
    .form-top {
      width: 600px;
      text-align: center;
      margin: 40px auto;
    }
    .form2 {
      width: 700px;
      margin: 50px auto 0;
      display: flex;
      ::v-deep .el-form-item {
        width: 50%;
        margin-bottom: 0;
      }
      ::v-deep .el-form-item__label {
        width: 205px !important;
        color: #333333 !important;
      }
      ::v-deep .el-form-item__content {
        margin-left: 200px !important;
      }
    }
    .uploadText {
      width: 100%;
      margin-bottom: 40px;
      color: #999999;
      font-size: 14px;
      p {
        text-align: center;
        a {
          color: #2b4fa5;
          text-decoration: none;
        }
      }
    }
    .agree {
      margin: 0px 0 34px;
      text-align: center;
      ::v-deep .el-checkbox {
        font-weight: bold;
        margin-right: 0;
      }
    }
    .footBtn2 {
      width: 100%;
      text-align: center;
      padding-bottom: 46px;
      .submit {
        width: 160px;
        height: 50px;
        font-size: 18px;
        color: #fff;
        background: linear-gradient(
          -90deg,
          rgba(49, 97, 187, 1) 0%,
          rgba(42, 72, 156, 1) 100%
        );
        border-radius: 4px;
        border: none;
      }
      .save {
        width: 160px;
        height: 50px;
        font-size: 18px;
        color: #3160ba;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #3160ba;
      }
    }
  }
}
</style>
